/*! CSS Used from: https://pump.fun/_next/static/css/30f9221d5509678a.css */
*,
:after,
:before {
    box-sizing: border-box;
    border: 0 solid #e5e7eb;
}

:after,
:before {
    --tw-content: "";
}

a {
    color: inherit;
    text-decoration: inherit;
}

button {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}

button {
    cursor: pointer;
}

:disabled {
    cursor: default;
}

img,
svg {
    display: block;
    vertical-align: middle;
}

img {
    max-width: 100%;
    height: auto;
}

*,
:after,
:before {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-scroll-snap-strictness: proximity;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59, 130, 246, .5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
}

.relative {
    position: relative;
}

.mr-4 {
    margin-right: 1rem;
}

.flex {
    display: flex;
}

.grid {
    display: grid;
}

.hidden {
    display: none;
}

.aspect-square {
    aspect-ratio: 1/1;
}

.h-4 {
    height: 1rem;
}

.h-5 {
    height: 1.25rem;
}

.h-fit {
    height: -moz-fit-content;
    height: fit-content;
}

.h-full {
    height: 100%;
}

.w-4 {
    width: 1rem;
}

.w-5 {
    width: 1.25rem;
}

.w-full {
    width: 100%;
}

.shrink-0 {
    flex-shrink: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-start {
    align-items: flex-start;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-items-end {
    justify-items: end;
}

.gap-1 {
    gap: .25rem;
}

.gap-2 {
    gap: .5rem;
}

.overflow-hidden {
    overflow: hidden;
}

.rounded {
    border-radius: .25rem;
}

.rounded-full {
    border-radius: 9999px;
}

.border {
    border-width: 1px;
}

.border-slate-500 {
    --tw-border-opacity: 1;
    border-color: rgb(100 116 139/var(--tw-border-opacity));
}

.bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253/var(--tw-bg-opacity));
}

.bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(252 165 165/var(--tw-bg-opacity));
}

.object-contain {
    -o-object-fit: contain;
    object-fit: contain;
}

.p-2 {
    padding: .5rem;
}

.px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
}

/* .text-sm {
    font-size: .555rem;
    line-height: 1.25rem;
} */

.text-slate-50 {
    --tw-text-opacity: 1;
    color: rgb(248 250 252/var(--tw-text-opacity));
}

.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

.animate-shake {
    animation: shake .7s ease-in-out;
}

.hover\:bg-slate-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(71 85 105/var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
    background-color: transparent;
}

.hover\:font-bold:hover {
    font-weight: 700;
}

.hover\:text-slate-50:hover {
    --tw-text-opacity: 1;
    color: rgb(248 250 252/var(--tw-text-opacity));
}

.hover\:underline:hover {
    text-decoration-line: underline;
}

@media (min-width:640px) {
    .sm\:block {
        display: block;
    }
}

@media (min-width:768px) {
    .md\:flex {
        display: flex;
    }

    .md\:gap-2 {
        gap: .5rem;
    }
}

@media (min-width:1024px) {
    .lg\:block {
        display: block;
    }
}

/*! CSS Used keyframes */
@keyframes shake {
    0% {
        transform: translateX(0);
        background-color: #ff0;
    }

    10% {
        transform: translateX(-25px);
        background-color: #ff0;
    }

    20% {
        transform: translateX(25px);
        background-color: #ff0;
    }

    30% {
        transform: translateX(-25px);
    }

    40% {
        transform: translateX(25px);
    }

    50% {
        transform: translateX(-25px);
    }

    60% {
        transform: translateX(25px);
    }

    70% {
        transform: translateX(-25px);
    }

    80% {
        transform: translateX(25px);
    }

    90% {
        transform: translateX(-25px);
    }

    to {
        transform: translateX(0);
    }
}